<template src="./CreateCV.html"> </template>

<script>
import VueGrapesjsComponent from "vue-grapesjs-component";
export default {
  name: "CreateCV",
  components: { VueGrapesjsComponent },
  methods: {}
};
</script>

<style lang="scss" scoped src="./CreateCV.scss"></style>
